<template>
    <div class="container">
        <div class="tours" id="tours">

        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        const scripts = [
            "https://c26.travelpayouts.com/content?trs=230710&shmarker=436504&departure=Moscow&type=package&redirect=_blank&background=true&powered_by=true&promo_id=8284",
            "https://tp.media/content?trs=230710&shmarker=436504&origin_iata=MOW&destination_iata=TR&currency=RUB&locale=ru&powered_by=true&min_lines=5&promo_id=4097&campaign_id=26"
        ];

        scripts.forEach(script => {
            let tag = document.head.querySelector(`[src="${script}"`);
            if (!tag) {
                tag = document.createElement("script");
                tag.setAttribute("src", script);
                tag.setAttribute("type", 'text/javascript');
                document.getElementById('tours').appendChild(tag)
            }
        });
    },
}
</script>